import React, { FunctionComponent, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { chartDataOptions, getChartDataSets } from '../../../utils/graphUtil';
import { chartColorPallete } from '../../../static/colorData';
import { Box, Typography } from '@mui/material';

interface IChartType {
  data: Array<{ name: string; count: number }>;
  chartHeight: number;
}

export const DoughnutComponent: FunctionComponent<IChartType> = ({ data, chartHeight = 80 }) => {
  const x_axis: Array<string> = [];
  const color_arr: Array<string> = [];
  const count_arr: Array<number> = [];
  let total_count = 0;

  for (const i in data) {
    const { name, count } = data[i];
    x_axis.push(name);
    color_arr.push(chartColorPallete[i]);
    count_arr.push(count);
    total_count += count;
  }

  const datasets = [
    {
      label: '',
      data: count_arr,
      backgroundColor: color_arr,
      hoverOffset: 2,
    },
  ];

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      <Box width={'42%'}>
        <Doughnut
          height={chartHeight}
          data={{
            labels: x_axis,
            datasets: datasets,
          }}
          options={{
            aspectRatio: 1,
            cutout: '40%',
            plugins: {
              tooltip: {
                titleFont: {
                  size: 10,
                },
                bodyFont: {
                  size: 10,
                },
              },
              legend: {
                display: false,
                responsive: true,
              },
            },
          }}
        />
      </Box>
      <Box width={'48%'}>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <Typography variant={'subtitle2'} width={'50%'} color={'gray'}>
            Topic
          </Typography>
          <Typography variant={'subtitle2'} width={'20%'} color={'gray'}>
            Count
          </Typography>
          <Typography variant={'subtitle2'} width={'20%'} color={'gray'}>
            Change %
          </Typography>
        </Box>
        {data.map((theme, idx) => (
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Box width={'50%'} display={'flex'}>
              <Box style={{ width: '17px', height: '17px', backgroundColor: color_arr[+idx] }} mr={1}></Box>
              <Typography variant={'subtitle1'} mt={-0.5}>
                {theme.name}
              </Typography>
            </Box>
            <Typography variant={'subtitle1'} width={'20%'}>
              {theme.count}
            </Typography>
            <Typography variant={'subtitle1'} width={'20%'}>
              {+((theme.count / total_count) * 100).toFixed(0)}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
