import makeStyles from '@mui/styles/makeStyles';

const loginStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F6FF',
  },
  loading: {
    color: theme.palette.primary.contrastText,
  },
  formBackground: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
  },
  formBox: {
    borderRadius: '1.5rem',
    border: `1px solid ${theme.palette.divider}`,
    padding: '3rem 3.5rem',
  },
  showTestimonial: {
    '@media screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  loginContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F6FF',
  },

  testimonialContainer: {
    backgroundColor: '#1D1C23',
    color: theme.palette.background.default,
    paddingBlock: theme.spacing(8),
    paddingLeft: '7%',
    paddingRight: '12%',
    height: 'calc(100vh - 128px)',
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (max-width: 1344px)': {
      height: '100vh',
    },
  },
  borderBox: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    padding: '2.1rem 2.5rem',
    marginTop: theme.spacing(3),
  },
  loginTitle: {
    fontSize: '2.2rem',
    lineHeight: '44.66px',
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  textField: {
    backgroundColor: theme.palette.background.default,
    '& .MuiOutlinedInput-input': { padding: '0.65rem 1.12rem', fontSize: '14px' },
  },

  appItem: {
    padding: '1rem 1.5rem',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.contrastText,
  },
  loginSeparator: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  googleLoginButton: {
    position: 'relative',
    backgroundColor: '#fff',
    fontSize: '14px',
    textTransform: 'none',
    color: '#fff',
    fontWeight: 700,
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
  },
  googleLoginBox: {
    backgroundColor: '#fff',
    height: '24px',
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    position: 'absolute',
    left: theme.spacing(1),
  },
}));

export default loginStyles;
