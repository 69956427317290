import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  List,
  ListItemIcon,
  ListItemButton,
  Checkbox,
  Button,
  InputAdornment,
} from '@mui/material';
import { useStyles } from '../../spotlight/Spotlight.style';
import { AttributeComponent } from '../../spotlight/AttributeComponent';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { ReactComponent as SearchIcon } from '../../../images/audience/search-icon.svg';
import { ICondition } from '../../../types/audience';
import { surveyStatusFeature } from '../../../static/staticData';
import { modifiedDate } from '../../../utils/DateUtil';
import { IReportQuery, IReportStore } from './report';
import { getStoreData, getStoreLabel } from './HelperUtil';
import { IReportMeta } from '../../../types/insight';

type QueryProps = {
  reportQuery: IReportMeta;
  reportStore: IReportStore;
  handleQueryUpdates: (
    query_key: string,
    id: string,
    secondary_label?: string,
    action_type?: string,
    search_str?: string,
  ) => void;
  handleAttributesQuery: (conditions: Array<ICondition>) => void;
  setReportQuery;
};

export const QueryComponent: FunctionComponent<QueryProps> = ({
  reportQuery,
  reportStore,
  handleQueryUpdates,
  setReportQuery,
}) => {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [activeName, setActiveName] = useState('');

  //open chip option based on question_type
  const [openQueryBlock, setQueryBlock] = useState<Array<string>>([]);
  const { attributes, triggers } = reportQuery;

  /**
   * handle dropdown options
   */
  const handleOpen = (name: string) => {
    setActiveName(name);
  };

  const handleAttributesQuery = (conditions: Array<ICondition>) => {
    setReportQuery({ ...reportQuery, attributes: [...conditions] });
  };

  const handleClose = (e: any, default_close: boolean) => {
    if (!default_close) {
      if (e && e.target && e.target.className) {
        if (!e.target.className.includes('MuiBackdrop-root')) {
          return;
        }
      }
    }

    setActiveName('');
    setSearchString('');
  };

  //returns query array from query Request
  const getQueryData = (query_key: string) => {
    const required_key = query_key === 'theme' ? 'options' : `${query_key}s`;
    return reportQuery[required_key] && reportQuery[required_key].data ? reportQuery[required_key].data : [];
  };

  const formatSelectListValue = (name: string, allSelectedOptions: any) => {
    const newText: Array<string> = [];
    const MAX_CHARS = 60;
    for (let i = 0; i < allSelectedOptions.length; i++) {
      const label = getStoreLabel(name, allSelectedOptions[i], reportStore);
      if (label) {
        newText.push(label);
      }
      if (newText.toString().length > MAX_CHARS) {
        break;
      }
    }
    return newText.toString().substring(0, MAX_CHARS);
  };

  //List View Item for select options
  const ListItem = (
    allSelectedOptions: Array<string>,
    name: string,

    item: {
      label: string;
      value: string;
      platform?: string;
      status?: string;
      created_at?: string;
      question_order?: number;
      survey_name?: string;
    },
    idx: number,
  ) => {
    const { label, value, platform, status, created_at, question_order, survey_name } = item;
    return (
      <ListItemButton key={idx} className={classes.multiListItem} onClick={() => handleQueryUpdates(name, value)} dense>
        <ListItemIcon>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
            classes={{
              root: classes.check,
              checked: classes.checked,
            }}
            checked={allSelectedOptions.includes(value)}
          />
        </ListItemIcon>
        {name === 'survey' && platform && status && created_at ? (
          <>
            <Box display="flex" alignItems={'center'}>
              <img
                src={platform ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg` : ''}
                alt=""
                style={{ marginLeft: 'auto', height: '26px' }}
              />
              <Box ml={1}>
                <Typography variant="subtitle1" lineHeight={1.05}>
                  {label}
                </Typography>

                <div style={{ marginTop: '-2px' }}>
                  <Typography
                    display="inline"
                    color={surveyStatusFeature[status].color}
                    className={classes.listSubtitle}
                  >
                    {status}{' '}
                  </Typography>
                  <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                    | {modifiedDate(created_at, 'DD MMM YY')}
                  </Typography>
                </div>
              </Box>
            </Box>
            <Box marginLeft={'auto'}>{status && surveyStatusFeature[status]?.icon}</Box>
          </>
        ) : name === 'question' && question_order && survey_name ? (
          <Box>
            <div>
              <Typography display="inline" color={'text.secondary'} className={classes.listSubtitle}>
                Q{question_order}{' '}
              </Typography>
              <Typography display="inline" color="text.secondary" className={classes.listSubtitle}>
                | {survey_name}
              </Typography>
            </div>
            <Typography variant="subtitle1" mt={-0.5} lineHeight={1.2} mb={0.8}>
              {label}
            </Typography>
          </Box>
        ) : (
          <Typography variant="subtitle1">{label}</Typography>
        )}
      </ListItemButton>
    );
  };

  const selectListOptions = (name: string) => {
    const allSelectedOptions = getQueryData(name);
    let allOptions = getStoreData(name, reportStore);

    //filter based on search str
    if (searchString.length > 0) {
      allOptions = allOptions.filter(o => o.label && o.label.toLowerCase().includes(searchString.toLowerCase()));
    }

    return (
      <Select
        variant="outlined"
        className={classes.activeSelectField}
        style={{ maxWidth: '70%', position: 'relative' }}
        renderValue={() =>
          allSelectedOptions && allSelectedOptions.length > 0 ? (
            <Typography variant={'subtitle1'} width={'30ch'} noWrap>
              {formatSelectListValue(name, allSelectedOptions)}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color={'primary.contrastText'}>
              {name === 'category' ? 'All categories' : `All ${name === 'survey' ? `active` : ''} ${name}s`}
            </Typography>
          )
        }
        value={allSelectedOptions.length > 0 ? allSelectedOptions : `All ${name}s`}
        name={name}
        open={activeName === name}
        onOpen={() => handleOpen(name)}
        onClose={e => handleClose(e, false)}
      >
        <TextField
          fullWidth
          placeholder="Search a value..."
          className={classes.searchTextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setSearchString(e.target.value);
          }}
        />

        <Box style={{ maxHeight: 450, overflowY: 'auto' }}>
          {allSelectedOptions.length > 0 && (
            <>
              <Box display={'flex'} justifyContent="space-between" mt={2}>
                <Typography fontSize={13} ml={2}>
                  Selected
                </Typography>
                <Typography
                  color={'text.secondary'}
                  fontSize={13}
                  mr={2}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleQueryUpdates(name, '', '', 'clear_all', searchString)}
                >
                  Clear All
                </Typography>
              </Box>
              <List className={classes.searchList}>
                {allOptions.map(
                  (item, idx) =>
                    item &&
                    item.label &&
                    allSelectedOptions.includes(item.value) &&
                    ListItem(allSelectedOptions, name, item, idx),
                )}
              </List>
              <hr className={classes.selectHrLine} />
            </>
          )}
          <Box display={'flex'} alignItems={'center'} justifyContent="space-between" mt={2} mb={1}>
            <Typography fontSize={13} ml={2}>
              {name === 'category' ? 'All categories' : `All ${name}s`}
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              className={classes.selectAllBox}
              onClick={() => handleQueryUpdates(name, '', '', 'select_all', searchString)}
            >
              Select All
            </Typography>
          </Box>
          <List className={classes.searchList} style={{ paddingBottom: 50 }}>
            {allOptions.map((item, idx) => item && item.label && ListItem(allSelectedOptions, name, item, idx))}
          </List>
        </Box>

        <Box className={classes.applySelectBox}>
          <Button variant="contained" className={classes.applyButton} onClick={e => handleClose(e, true)}>
            <Typography fontSize={13} fontWeight={700} textAlign="right">
              Apply
            </Typography>
          </Button>
        </Box>
      </Select>
    );
  };

  return (
    <Box display="flex" className={classes.spotlightBox}>
      <Box width={'100%'}>
        <Box className={classes.rightBox}>
          <Box display="flex" alignItems="center" className={classes.hoverContent}>
            <Typography variant="subtitle1" mr={1} fontWeight={700}>
              Answered by users in
            </Typography>
            {selectListOptions('cohort')}
          </Box>

          <AttributeComponent
            queryAttributes={attributes}
            triggers={triggers}
            selectListOptions={selectListOptions}
            handleAttributesQuery={handleAttributesQuery}
            isNewChart={true}
            chartId={''}
            handleQueryUpdates={handleQueryUpdates}
          />
        </Box>
      </Box>
    </Box>
  );
};
