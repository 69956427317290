import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Typography, Box, LinearProgress, Theme } from '@mui/material';
import { ProgressColorUtil } from './ProgressColorUtil';
import { numberWithCommas } from './stringUtil';
import { IOthersText } from '../types/survey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      height: '4px',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

interface ProgressItem {
  value: number;
  count?: number;
  label?: string;
  has_text_input?: boolean;
  handleOthersModal: (others_text: Array<IOthersText>) => void;
  select_input_texts: Array<IOthersText>;
}

export const LinearProgressWithLabel: FunctionComponent<ProgressItem> = ({
  value,
  count = 0,
  label,
  has_text_input = false,
  handleOthersModal,
  select_input_texts = [],
}) => {
  const classes = useStyles();
  const other_text_link = has_text_input && select_input_texts.length > 0;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant={'subtitle1'} display="inline">
            {`${Math.round(value)}%`} .{' '}
          </Typography>
          {label && (
            <Typography variant={'subtitle1'} display="inline">
              {label}
            </Typography>
          )}
        </Box>
        <Typography
          variant={'subtitle1'}
          onClick={() => (other_text_link ? handleOthersModal(select_input_texts) : null)}
          style={
            other_text_link
              ? { overflow: 'unset', textDecoration: 'underline', cursor: 'pointer', color: 'blue' }
              : { overflow: 'unset' }
          }
          noWrap
        >
          {count ? `${numberWithCommas(count)} responses` : `0 responses`}
        </Typography>
      </Box>
      <Box width="100%" mt={0.5} pb={2.5}>
        <LinearProgress
          variant="determinate"
          value={value}
          className={classes.progress}
          sx={{
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: ProgressColorUtil(value),
            },
          }}
        />
      </Box>
    </>
  );
};
