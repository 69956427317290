export const reportInitialData = {
  triggers: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  date_range: {
    type: 'days',
    days_count: 30,
    time_from: '',
    time_to: '',
  },
  cohorts: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  insight_store: {
    type: 'all',
    data: [],
    rule_type: 'in',
  },
  attributes: [],
  keyword_include: '',
  source: [],
};

export const reportFetchData = {
  trigger_store: [],
  cohort_store: [],
  attributes: [],
};

export const reportDayCount = { 'Daily Report': 2, 'Weekly Report': 14, 'Monthly Report': 60 };

export const sentimentObj = { positive: '😃', negative: '😞', neutral: '😐' };
