import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const homeStyles = makeStyles((theme: Theme) =>
  createStyles({
    templatePaper: {
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(3.2),
      paddingBlock: theme.spacing(2.6),
      border: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
    },
    progressText: { color: theme.palette.success.main, marginLeft: 5 },
    filterSelect: {
      width: '150px',
      marginTop: theme.spacing(0.7),
      fontWeight: 700,
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem' },
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
      },
    },

    loading: {
      color: theme.palette.primary.contrastText,
    },
    startBox: {
      border: '1px solid rgba(255, 255, 255, 0.05)',
      borderRadius: 10,
    },

    responseBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.3),
    },
    surveyNameText: {
      lineHeight: 1.25,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },

    researchMenu: {
      '& .MuiPaper-root': {
        width: '230px',
      },
    },
    documentBox: {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(3.2),
      borderRadius: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    viewDocumentButton: {
      backgroundColor: '#F5F6FC',
      border: `1px solid #E6E7FA`,
      marginLeft: 'auto',
      paddingBlock: theme.spacing(0.6),
      paddingInline: theme.spacing(2),

      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#F5F6FC',
        color: theme.palette.primary.contrastText,
      },
    },
    viewTipBox: {
      backgroundColor: '#F5F6FC',
      border: `1px solid #E6E7FA`,
      borderRadius: theme.spacing(2),
      paddingInline: theme.spacing(1.3),
      cursor: 'pointer',
    },
    showMoreText: {
      fontWeight: 700,
      textAlign: 'center',
      cursor: 'pointer',
      marginTop: theme.spacing(3),
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(3),
    },
    searchTextField: {
      width: '25vw',
      height: theme.spacing(5),
      '& .MuiOutlinedInput-input': {
        paddingRight: '0.6rem',
        paddingBlock: '0.6rem',
      },
    },
  }),
);
