import React, { useEffect, useState, FunctionComponent } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Grid,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  CircularProgress,
  Select,
  Checkbox,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useStyles } from './PerformanceStyle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import SurveyLinkDialog from './SurveyLinkModal';
import SurveySettingsDialog from './SettingsModal';
import PerformanceFilterDrawer from './PerformanceFilterDrawer';

import dotImg from '../../images/surveyPerformance/green-dot.svg';
import previewImg from '../../images/preview-icon.svg';
import { ReactComponent as DownloadImg } from '../../images/surveyPerformance/download-img.svg';
import responseArrowImg from '../../images/surveyPerformance/response-arrow.svg';
import filterImg from '../../images/surveyPerformance/filter-icon.svg';
import blueFilterImg from '../../images/surveyPerformance/blue-filter-icon.svg';

import { usePapaParse } from 'react-papaparse';
import { filterLanguages, filterResponses, surveyDropdownOptions } from '../../static/staticData';
import GraphComponent from './GraphComponent';
import surveyStyles from '../common/SurveyStyles';
import AlertUtil from '../../utils/AlertUtil';
import { responseText, linkLanguages, filterConditionsHandler } from '../../utils/performanceUtil';
import InputThemeDialog from './PerformanceModal';
import OtherTextModal from './OtherTextModal';
import StatusConfirmationModal from './StatusConfirmModal';
import SurveyDemoComponent from '../demo/SurveyDemoComponent';
import { ISurveyDetails, ISurvey, IResponses, AllResponses, IAdvancedCondition, IOthersText } from '../../types/survey';
import {
  fetchSurveyDetails,
  fetchSurveyById,
  editSurveyStatus,
  fetchSurveyResponseGroups,
  fetchSurveyViews,
  fetchGraphByQuestionId,
} from '../../services/surveyService';
import { fetchResponseCsv, fetchCohortById } from '../../services/audienceService';
import buttonLinkIcon from '../../images/surveyPerformance/button-link-icon.svg';
import { IMemberDetails } from '../../types/member';
import { getResponseRate, getValue } from '../../utils/questionUtil';
import { modifiedDate, dateRangeQuery } from '../../utils/DateUtil';
import { capitalizeFirstLetter, getImageId, responseDurationTxt } from '../../utils/stringUtil';
import { StyledTableRow } from '../common/tableStyles';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { IDateRange } from '../spotlight/spotlight';
import { DateFilter } from '../spotlight/DateFilter';
import UserDrawerComponent from '../audience/UserDrawerComponent';
import PrototypePreview from '../editor/PrototypePreview';
import { RootState, useAppDispatch } from '../../store';
import { resetCondition } from '../../slices/performanceSlice';

import shareImg from '../../images/surveyPerformance/share-icon.svg';

import { DashboardModal } from '../dashboard/DashboardModal';
import { IWorkspace } from '../../types/workspace';
import { workspaceById } from '../../services/workspaceService';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Iparams {
  survey_id: string;
}

const SurveyDemo = ({ surveyInfo, handleSurveySettings }) => {
  const classes = useStyles();
  return (
    <Box mb={2.5}>
      <SurveyDemoComponent {...surveyInfo} source="survey details" />
      <Box display="flex" textAlign={'right'} justifyContent="flex-end" marginRight={'110px'}>
        <img src={previewImg} alt="" style={{ marginRight: 5 }} />
        <Typography variant={'subtitle1'} color={'#696969'}>
          PREVIEW
        </Typography>
      </Box>

      <Typography px={3} py={1} variant={'subtitle1'} className={classes.settingsBox} onClick={handleSurveySettings}>
        View survey settings
      </Typography>
    </Box>
  );
};

const SurveyPerformanceComponent: FunctionComponent<{ member: IMemberDetails }> = ({ member }) => {
  const classes = useStyles();
  const common = surveyStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { jsonToCSV } = usePapaParse();

  const [tabValue, setTabValue] = React.useState(0);
  const [openToggle, toggleModal] = React.useState(false);
  const [openOthersToggle, toggleOthersModal] = React.useState(false);
  const [performanceAnchor, setPerformanceAnchor] = React.useState<null | HTMLElement>(null);
  const [csvBtnAnchor, setCSVBtnAnchor] = React.useState<null | HTMLElement>(null);

  const [rightState, setRightState] = React.useState(false);

  const params: Iparams = useParams();
  const { survey_id } = params;
  const language_code = 'en';

  const [surveyDetails, setSurveyDetails] = useState<ISurveyDetails>();
  const [surveyResponses, setSurveyResponses] = useState<Array<Array<AllResponses>>>();
  const [questionThemes, setQuestionThemes] = useState<any>({});
  const [surveyViews, setSurveyViews] = useState<Array<any>>([]);
  const [surveyInfo, setSurveyInfo] = useState<ISurvey>();
  const [workspace, setWorkspace] = useState<IWorkspace>();

  const [isLoading, setLoading] = useState(true);
  const [isTabLoading, setTabLoading] = useState(false);
  const [showOriginal, setOriginal] = useState(false);
  const [cohortName, setCohortName] = useState('');

  const [responseItem, setResponseItem] = useState<IResponses>();
  const [otherSelectTexts, setOtherSelectTexts] = useState<Array<IOthersText>>([]);
  const [questionText, setQuestionText] = useState('');
  const [themeName, setThemeName] = useState('');
  const [themeText, setThemeText] = useState('');
  const [themeResponse, setThemeResponse] = useState(Number);
  const [pageCount, setPageCount] = useState(0);
  const [activeShowMore, setActiveShowMore] = useState(false);

  const [viewPageCount, setViewPageCount] = useState(0);
  const [viewShowMore, setViewShowMore] = useState(false);

  const [filterLanguage, setFilterLanguage] = useState('English translation');
  const [showUserDrawer, toggleUserDrawer] = React.useState(false);
  const [userId, setUserId] = useState('');

  const [openLinkToggle, toggleLinkModal] = useState(false);
  const [openSettingsToggle, toggleSettingsModal] = useState(false);
  const [openStatusToggle, toggleStatusModal] = useState(false);
  const [openCreateDashboardToggle, toggleCreateDashboardModal] = useState(false);
  const [advancedConditions, setAdvancedConditions] = useState<IAdvancedCondition>({
    only_high_intent: false,
  });

  const [convertStatus, setConvertStatus] = useState('');
  const [dateRange, setDateRange] = useState<IDateRange>({
    type: 'custom',
    days_count: 0,
    time_from: '',
    time_to: '',
  });

  const [chartQuestionIds, setChartQuestionId] = useState<Array<string>>([]);
  const [chartData, setChartData] = useState({});

  const { w_role } = member;

  const boards = useSelector((state: RootState) => state.chart);

  // conditions for performance filter (coming from slice)
  const conditions = useSelector((state: RootState) => state.performanceConditions);
  const valid_filter_conditions = filterConditionsHandler(conditions).length;
  const isFilterAdded = advancedConditions.only_high_intent || valid_filter_conditions === conditions.length;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'survey details viewed', {
        tab: 'overview',
        'survey id': survey_id,
      });
    }
  }, [tracking_info]);

  const handleAmplitudeClick = async (tab_name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'survey details viewed', {
        tab: tab_name,
        'survey id': survey_id,
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'stage dropdown', {
        'survey id': survey_id,
      });
    }
    setPerformanceAnchor(event.currentTarget);
  };

  //handle null & timeoffset shifts
  let formattedStartDate = dateRange.time_from ? modifiedDate(dateRange.time_from.toString(), '') : '';
  if (!formattedStartDate) {
    formattedStartDate = moment()
      .subtract(6, 'days')
      .format('');
  }

  let formattedEndDate = dateRange.time_to ? modifiedDate(dateRange.time_to.toString(), '') : '';
  if (!formattedEndDate) {
    formattedEndDate = modifiedDate(moment().format(), '');
  }

  const activateUserDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    toggleUserDrawer(!showUserDrawer);
  };

  const handleAdvancedConditions = only_high_intent => {
    setAdvancedConditions({ only_high_intent });
  };

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setRightState(!rightState);
  };

  const handleDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setCSVBtnAnchor(event.currentTarget);
  };

  const handleOthersModal = (othersTexts: Array<IOthersText>) => {
    toggleOthersModal(true);
    setOtherSelectTexts(othersTexts);
  };

  const handleInputResponseModal = (
    item: IResponses,
    question_text: string,
    theme_name: string,
    theme_text: string,
    total_count: number,
  ) => {
    toggleModal(true);
    setResponseItem(item);
    setQuestionText(question_text);
    setThemeName(theme_name);
    setThemeText(theme_text);
    setThemeResponse(total_count);
  };

  const handleCloseDropDown = () => {
    setPerformanceAnchor(null);
    setCSVBtnAnchor(null);
  };

  // paginated survey response group fetch fn. increment page count if showmore is true
  const fetchSurveyResponse = async (is_showmore = false) => {
    let currPageCount = pageCount;

    if (is_showmore) {
      currPageCount = currPageCount + 1;
    } else {
      setTabLoading(true);
    }

    const responseResult = await fetchSurveyResponseGroups(survey_id, {
      hours: -1,
      from_date: formattedStartDate,
      to_date: formattedEndDate,
      page: currPageCount,
      conditions,
      advanced_conditions: { ...advancedConditions },
    });

    if (responseResult.length === 20) {
      setActiveShowMore(true);
    } else {
      setActiveShowMore(false);
    }

    if (!is_showmore) {
      setSurveyResponses(responseResult);
    } else {
      if (surveyResponses && responseResult) {
        setSurveyResponses([...surveyResponses, ...responseResult]);
      } else {
        setSurveyResponses([]);
      }
    }

    setPageCount(currPageCount);
    setTabLoading(false);
  };

  // paginated survey response group fetch fn. increment page count if showmore is true
  const fetchSurveyView = async (is_showmore = false) => {
    let currPageCount = viewPageCount;

    if (currPageCount === 0) {
      setTabLoading(true);
    }

    if (is_showmore) {
      currPageCount = currPageCount + 1;
    } else {
      setTabLoading(true);
    }

    const responseResult = await fetchSurveyViews(survey_id, {
      from_date: formattedStartDate,
      to_date: formattedEndDate,
      page: currPageCount,
      conditions,
      advanced_conditions: { ...advancedConditions },
    });

    if (responseResult.length === 20) {
      setViewShowMore(true);
    } else {
      setViewShowMore(false);
    }

    if (!is_showmore) {
      setSurveyViews(responseResult);
    } else {
      if (surveyViews && responseResult) {
        setSurveyViews([...surveyViews, ...responseResult]);
      } else {
        setSurveyViews([]);
      }
    }

    setViewPageCount(currPageCount);
    setTabLoading(false);
  };

  const fetchSurveyInfo = async (adjustStartDate = false) => {
    if (adjustStartDate) {
      const surveyData: ISurvey = await fetchSurveyById(survey_id, language_code);
      if (surveyData) {
        setSurveyInfo(surveyData);

        //adjust start date if it is created in last week (only on mounting)
        if (surveyData.created_at) {
          const createdAt = modifiedDate(surveyData.created_at, '');
          setDateRange({
            ...dateRange,
            time_from: createdAt,
            time_to: formattedEndDate,
          });
          formattedStartDate = modifiedDate(surveyData.created_at, '');
        }
      }

      const workspaceData: IWorkspace = await workspaceById();
      if (workspaceData) {
        setWorkspace(workspaceData);
      }
    }

    const surveyDetails: ISurveyDetails = await fetchSurveyDetails(survey_id, {
      hours: -1,
      from_date: formattedStartDate,
      to_date: formattedEndDate,
      conditions,
      advanced_conditions: { ...advancedConditions },
    });

    if (surveyDetails) {
      setSurveyDetails(surveyDetails);

      //only gets called the first instance
      if (surveyDetails.question_stats && Object.keys(questionThemes).length === 0) {
        const question_themes = {};
        for (const i in surveyDetails.question_stats) {
          const { question_id, question_type, response_texts = [] } = surveyDetails.question_stats[i];
          if (question_type === 'input_feedback' && response_texts && response_texts.length > 0) {
            let theme_item = response_texts.map(r => {
              if (r.theme_name !== 'untagged') {
                return { option_text: r.theme_name, _id: r.theme_id.toString() };
              }
            });

            //remove undefined from the map
            theme_item = theme_item.filter(t => t);
            question_themes[question_id] = theme_item || [];
          }
        }

        setQuestionThemes(question_themes);
      }
    }

    //Updating tab values based on conditions
    if (tabValue === 1) {
      setPageCount(0);
      fetchSurveyResponse();
    }

    if (tabValue === 2) {
      setViewPageCount(0);
      fetchSurveyView();
    }

    setLoading(false);
  };

  const fetchCohorts = async () => {
    if (surveyInfo) {
      const { user_cohort } = surveyInfo;
      if (user_cohort !== '') {
        const cohorts = await fetchCohortById(user_cohort);
        if (cohorts) {
          const { cohort_name } = cohorts;
          if (cohort_name) setCohortName(cohort_name);
        }
      } else {
        setCohortName('All users');
      }
    }
  };

  const handleSurveySettings = () => {
    fetchCohorts();
    toggleSettingsModal(true);
  };

  const handleSurveyType = async (type: string) => {
    let statusUpdateResults = null;
    if (type === 'Pause Survey') {
      statusUpdateResults = await editSurveyStatus(survey_id, 'pause');
    } else if (type === 'Archive Survey') {
      statusUpdateResults = await editSurveyStatus(survey_id, 'archive');
    } else if (type === 'Complete Survey') {
      statusUpdateResults = await editSurveyStatus(survey_id, 'complete');
    }

    if (statusUpdateResults) {
      AlertUtil.fire({
        icon: 'success',
        title: `Survey status updated`,
      });
      await fetchSurveyInfo();
    }
    handleCloseDropDown();
  };

  const handleStatusConfirmation = (opt: any) => {
    setConvertStatus(opt);
    toggleStatusModal(true);
  };

  const handleSurveyLive = async () => {
    const statusUpdateResults = await editSurveyStatus(survey_id, 'pause');
    if (statusUpdateResults) {
      if (tracking_info) {
        amplitudeEvent(tracking_info, 'pause & edit', {
          'survey id': survey_id,
        });
      }
      history.push(`/survey/${survey_id}/editor`);
    }
  };

  //charts
  const chartToggleHandler = async (question_id: string) => {
    if (chartQuestionIds.includes(question_id)) {
      const filterQuestionIds = chartQuestionIds.filter(c => c !== question_id);
      setChartQuestionId(filterQuestionIds);
    } else {
      if (!chartData[question_id]) {
        const responseResult = await fetchGraphByQuestionId(
          {
            from_date: formattedStartDate,
            to_date: formattedEndDate,
            conditions,
            advanced_conditions: { ...advancedConditions },
          },
          survey_id,
          question_id,
        );

        if (responseResult && responseResult.data) {
          chartData[question_id] = responseResult.data;
          setChartData(chartData);
        }
      }
      setChartQuestionId([question_id, ...chartQuestionIds]);
    }
  };

  const chartTimeChangeHandler = async () => {
    await fetchSurveyInfo();
    const newChartData = {};
    const { time_from, time_to } = dateRange;

    for (const i in chartQuestionIds) {
      const responseResult = await fetchGraphByQuestionId(
        {
          from_date: time_from,
          to_date: time_to,
          conditions,
          advanced_conditions: { ...advancedConditions },
        },
        survey_id,
        chartQuestionIds[i],
      );
      if (responseResult && responseResult.data) {
        newChartData[chartQuestionIds[i]] = responseResult.data;
      }
    }
    setChartData(newChartData);
  };

  //handle date range changes similar to query filters
  const handleDateChanges = (range_type: string, field_name: string, value: string | number) => {
    const curr_date_query = dateRange;
    curr_date_query['type'] = range_type;
    if (field_name === 'days_count') {
      curr_date_query[field_name] = +value;
      const { time_to, time_from } = dateRangeQuery(dateRange);
      setDateRange({
        ...curr_date_query,
        time_to,
        time_from,
      });
      return;
    } else {
      curr_date_query[field_name] = value;
    }
    setDateRange({
      ...curr_date_query,
    });
  };

  useEffect(() => {
    if (survey_id) {
      dispatch(resetCondition());
      fetchSurveyInfo(true);
    }
  }, [survey_id]);

  useEffect(() => {
    if (dateRange && !isLoading) {
      chartTimeChangeHandler();
    }
  }, [dateRange]);

  //called on clear filter
  //surveyInfo is added to avoid initial call. rightState to ensure drawer changes doesnt cause re-render
  useEffect(() => {
    if (surveyInfo) {
      fetchSurveyInfo();
    }
  }, [conditions]);

  //unmount reset value
  useEffect(() => {
    return () => {
      dispatch(resetCondition());
    };
  }, []);

  const getRuleSets = (question_id: string) => {
    if (!surveyInfo) {
      return [];
    }
    const { questions } = surveyInfo;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].question_id === question_id) {
        return questions[i].rule_sets;
      }
    }
    return [];
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1) {
      fetchSurveyResponse();
      handleAmplitudeClick('responses');
    } else if (newValue === 2) {
      fetchSurveyView();
      handleAmplitudeClick('activity');
    } else {
      handleAmplitudeClick('overview');
    }
  };

  const handleUserDrawer = async (e: any, user_id: string, open: boolean) => {
    if (open) {
      setUserId(user_id);
      activateUserDrawer(e);
    }
  };

  if (!surveyDetails || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const { survey, all_responses, stats, question_stats } = surveyDetails;

    function save(filename: string, data: string) {
      const blob = new Blob([data], {
        type: 'text/csv',
      });

      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }

    const handleDownloadCSV = async (fileName: string, responses: string) => {
      //flatten response array
      const { time_from, time_to } = dateRange;
      const filteredResponses: Array<any> = await fetchResponseCsv(
        {
          conditions,
          advanced_conditions: { ...advancedConditions },
          from_date: time_from,
          to_date: time_to,
          responses,
        },
        survey_id,
      );
      if (filteredResponses) {
        //convert jsonArray to stringified csv data
        const responses: any = jsonToCSV(filteredResponses);

        if (!fileName) {
          fileName = 'blitz_survey';
        } else {
          fileName = fileName
            .toLowerCase()
            .replace(/ /g, '_')
            .replaceAll('.', '_');
        }

        save(fileName, responses);
      }
      handleCloseDropDown();
      if (tracking_info) {
        amplitudeEvent(tracking_info, 'download responses', {
          'survey id': survey_id,
        });
      }
    };

    const handleCohortItem = async (e: any, open: boolean, name: string) => {
      if (open) {
        toggleDrawer(e);
      }
    };

    const createBtnHandler = () => {
      if (boards.length < 10) {
        toggleCreateDashboardModal(true);
        handleCloseDropDown();
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: `Max 10 dashboards can be created`,
        });
      }
    };

    return (
      <div>
        {openCreateDashboardToggle && (
          <DashboardModal
            toggleModal={toggleCreateDashboardModal}
            open={openCreateDashboardToggle}
            surveyId={survey_id}
          />
        )}
        {surveyInfo && rightState && (
          <PerformanceFilterDrawer
            toggleDrawer={toggleDrawer}
            rightState={rightState}
            fetchSurveyInfo={fetchSurveyInfo}
            surveyInfo={surveyInfo}
            allResponses={all_responses}
            questionThemes={questionThemes}
            advancedConditions={advancedConditions}
            handleAdvanceConditions={handleAdvancedConditions}
          />
        )}

        {openToggle && (
          <InputThemeDialog
            toggleModal={toggleModal}
            open={openToggle}
            responseItem={responseItem}
            questionText={questionText}
            themeName={themeName}
            themeText={themeText}
            themeResponse={themeResponse}
            handleUserDrawer={handleUserDrawer}
          />
        )}

        {openOthersToggle && (
          <OtherTextModal
            toggleModal={toggleOthersModal}
            open={openOthersToggle}
            otherSelectTexts={otherSelectTexts}
            questionText={''}
            themeName={''}
            handleUserDrawer={handleUserDrawer}
          />
        )}

        {openLinkToggle && (
          <SurveyLinkDialog
            toggleModal={toggleLinkModal}
            open={openLinkToggle}
            survey_link={surveyInfo?.link_url ? surveyInfo.link_url : ''}
            survey_type={surveyInfo?.research_type ? surveyInfo.research_type : 'survey'}
            languages={surveyInfo?.languages ? linkLanguages(surveyInfo.languages) : []}
            custom_domain={workspace && workspace.custom_domain ? workspace.custom_domain : ''}
          />
        )}

        {openStatusToggle && (
          <StatusConfirmationModal
            toggleModal={toggleStatusModal}
            open={openStatusToggle}
            handleSurveyType={handleSurveyType}
            convertStatus={convertStatus}
            researchType={survey.research_type}
            handleCloseDropDown={handleCloseDropDown}
          />
        )}

        {openSettingsToggle && (
          <SurveySettingsDialog
            toggleSettingsModal={toggleSettingsModal}
            open={openSettingsToggle}
            surveyInfo={surveyInfo}
            cohortName={cohortName}
          />
        )}
        {userId && showUserDrawer && (
          <UserDrawerComponent
            toggleDrawer={activateUserDrawer}
            anchor={'right'}
            showUserDrawer={showUserDrawer}
            userId={userId}
          />
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" width={'60%'}>
            <img
              src={
                surveyInfo && (surveyInfo.research_type === 'prototype' || surveyInfo.research_type === 'quiz')
                  ? `https://cdn.blitzllama.com/survey/platform/${surveyInfo.research_type}.svg`
                  : survey && survey.platform
                  ? `https://cdn.blitzllama.com/survey/platform/${survey.platform}-sq.svg`
                  : ''
              }
              alt=""
              width="45px"
              height="45px"
            />
            <div
              style={{
                marginLeft: 20,
              }}
            >
              <Typography variant={'h4'} pb={1}>
                {survey.survey_name}
              </Typography>
              <Typography variant={'subtitle1'} display="inline">
                Created by {survey.created_by} on {modifiedDate(survey.created_at, 'll')} | Survey Ends on{' '}
                {modifiedDate(survey.survey_end_date, 'll')}
              </Typography>
            </div>
          </Box>
          <Box display="flex">
            {w_role === 'editor' &&
              survey.status !== 'completed' &&
              survey.status !== 'archived' &&
              (survey.status !== 'live' ? (
                <Button
                  variant="contained"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() =>
                    history.push(`/${surveyInfo ? surveyInfo?.research_type : 'survey'}/${survey_id}/editor`)
                  }
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => handleSurveyLive()}
                >
                  Pause & edit
                </Button>
              ))}

            {surveyInfo?.platform === 'link' && survey.status === 'live' && (
              <Button
                variant="outlined"
                style={{
                  marginLeft: 10,
                  whiteSpace: 'nowrap',
                }}
                onClick={() => toggleLinkModal(true)}
              >
                <img
                  src={buttonLinkIcon}
                  alt=""
                  style={{
                    marginRight: 5,
                  }}
                />
                Survey Link
              </Button>
            )}
            {w_role === 'editor' ? (
              <div>
                <Button
                  className={classes.statusButton}
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleClick}
                >
                  {survey.status === 'live' && (
                    <Box display="flex" alignItems="center">
                      <img src={dotImg} alt="" />
                      <Typography variant={'subtitle1'} fontWeight={700} color={'success.main'} pl={1}>
                        In Progress
                      </Typography>
                    </Box>
                  )}
                  {survey.status !== 'live' && (
                    <Typography
                      variant={'subtitle1'}
                      fontWeight={700}
                      color={'text.primary'}
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {survey.status}
                    </Typography>
                  )}
                </Button>

                <Menu
                  id="customized-menu"
                  anchorEl={performanceAnchor}
                  keepMounted
                  open={Boolean(performanceAnchor)}
                  onClose={handleCloseDropDown}
                  style={{
                    marginTop: 8,
                  }}
                >
                  {surveyDropdownOptions[survey.status] &&
                    surveyDropdownOptions[survey.status].map((opt, key) => (
                      <MenuItem key={key} onClick={() => handleStatusConfirmation(opt)}>
                        {opt.replace('Survey', capitalizeFirstLetter(survey.research_type))}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            ) : (
              <Button
                variant="outlined"
                style={{
                  marginLeft: 10,
                }}
              >
                {survey.status === 'live' && (
                  <Box display="flex" alignItems="center">
                    <img src={dotImg} alt="" />
                    <Typography color={'success.main'} pl={1}>
                      In Progress
                    </Typography>
                  </Box>
                )}
                {survey.status !== 'live' && (
                  <Typography
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    {survey.status}
                  </Typography>
                )}
              </Button>
            )}
          </Box>
        </Box>
        <Grid container mt={2.2} mb={3}>
          <Grid item lg={6} display="flex" alignItems="center" justifyContent="space-between">
            <div>
              <DateFilter date_range={dateRange} handleDateChanges={handleDateChanges} hideMonths={true} />
            </div>
          </Grid>
          <Grid item lg={6} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
            <Box display={'flex'} alignItems={'center'}>
              {isFilterAdded && (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  className={classes.clearFilterBox}
                  onClick={() => {
                    dispatch(resetCondition());
                    setAdvancedConditions({ only_high_intent: false });
                  }}
                >
                  <CloseIcon
                    style={{
                      height: '12px',
                      width: '12px',
                    }}
                  />
                  <Typography variant="subtitle2">Clear filter</Typography>
                </Box>
              )}
              <Button
                className={isFilterAdded ? classes.activeFilterButton : classes.performaceFilterButton}
                style={{
                  color: !isFilterAdded ? 'black' : '',
                }}
                onClick={e => {
                  handleCohortItem && handleCohortItem(e, true, 'filter');
                }}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <img src={isFilterAdded ? blueFilterImg : filterImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} ml={1}>
                    Filter {isFilterAdded && `(${valid_filter_conditions})`}
                  </Typography>
                </Box>
              </Button>
            </Box>
            <Box>
              <Button
                className={classes.performaceFilterButton}
                aria-controls="customized-menu"
                startIcon={<DownloadImg />}
                endIcon={<ExpandMoreIcon />}
                onClick={handleDownloadClick}
                style={{
                  color: 'black',
                }}
              >
                Share & export
              </Button>

              <Menu
                id="customized-menu"
                anchorEl={csvBtnAnchor}
                keepMounted
                open={Boolean(csvBtnAnchor)}
                onClose={handleCloseDropDown}
                style={{
                  marginTop: 8,
                }}
              >
                <MenuItem disabled>
                  <Typography variant="subtitle2">EXPORT OPTIONS</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleDownloadCSV(survey?.survey_name, 'csv_responses')}>
                  Responses (Translated)
                </MenuItem>
                <MenuItem onClick={() => handleDownloadCSV(survey?.survey_name, 'csv_responses_raw')}>
                  Responses (Original)
                </MenuItem>
                <MenuItem onClick={() => handleDownloadCSV(survey?.survey_name, 'csv_responses_with_attributes')}>
                  Responses with Attributes
                </MenuItem>
                <MenuItem onClick={() => handleDownloadCSV(survey?.survey_name, 'csv_views')}>Activity</MenuItem>
                <Box
                  px={3}
                  display={'flex'}
                  alignItems={'center'}
                  className={classes.createLinkBox}
                  onClick={() => createBtnHandler()}
                >
                  <img src={shareImg} alt="" />
                  <Typography color="primary.contrastText" pl={1}>
                    Generate Report
                  </Typography>
                </Box>
              </Menu>
            </Box>
          </Grid>
        </Grid>
        <Grid container mb={2.5}>
          <Grid item lg={7} display={'flex'} justifyContent="space-between">
            <Box display="flex" alignItems="center" className={classes.circularProgress}>
              <div
                style={{
                  width: 48,
                  height: 48,
                }}
              >
                <CircularProgressbar
                  value={getValue(survey.status, stats.response_complete, stats.survey_response_limit) as number}
                  styles={buildStyles({
                    pathColor: '#FF8541',
                    trailColor: '#ECDCD3',
                  })}
                />
              </div>
              <div
                style={{
                  paddingLeft: 10,
                }}
              >
                <Typography variant="h4" color={'warning.main'}>
                  {getValue(survey.status, stats.total_responses, stats.survey_response_limit)}%
                </Typography>
                <Typography variant="subtitle1" color={'warning.main'}>
                  Survey complete
                </Typography>
              </div>
            </Box>
            <div>
              <Typography variant="h4">
                <CountUp end={stats.response_complete} duration={3} />
              </Typography>
              <Typography variant="subtitle1">Completed</Typography>
            </div>
            <div>
              <Typography variant="h4">
                <CountUp end={stats.total_responses} duration={3} />
              </Typography>
              <Typography variant="subtitle1">Responses</Typography>
            </div>
            <div>
              <Typography variant="h4">
                <CountUp end={stats.sent_count} duration={3} />
              </Typography>
              <Typography variant="subtitle1">Viewed</Typography>
            </div>
            <div>
              <Typography variant="h4">{getResponseRate(stats.total_responses, stats.sent_count)}%</Typography>
              <Typography variant="subtitle1">Response rate</Typography>
            </div>
          </Grid>
        </Grid>

        <AppBar position="static" className={common.tab}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 0 ? 'primary.contrastText' : ''}>Overview</Typography>
                </Box>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 1 ? 'primary.contrastText' : ''}>Responses</Typography>
                </Box>
              }
              {...a11yProps(1)}
            />

            <Tab
              label={
                <Box display="flex" alignItems="center">
                  <Typography color={tabValue === 2 ? 'primary.contrastText' : ''}>Activity</Typography>
                </Box>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>

        <Grid container>
          <Grid item lg={8}>
            <Box display={'flex'} alignItems={'center'}>
              <Box mt={2}></Box>

              {tabValue === 1 && (
                <Select
                  variant="outlined"
                  displayEmpty
                  className={classes.filterSelect}
                  value={filterLanguage}
                  renderValue={() => filterLanguage}
                  onChange={e => {
                    setOriginal(e.target.value !== 'English translation');
                    setFilterLanguage(e.target.value);
                  }}
                >
                  {filterLanguages.map(({ label, value }, idx) => (
                    <MenuItem key={idx} value={value}>
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        style={{
                          marginRight: '8px',
                        }}
                        classes={{
                          root: classes.check,
                          checked: classes.checked,
                        }}
                        checked={filterLanguage === value}
                      />
                      <Typography variant={'subtitle1'}>{label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>

            <TabPanel value={tabValue} index={0}>
              <Box mb={2.5}>
                {question_stats && (
                  <GraphComponent
                    questionStats={question_stats}
                    handleInputResponseModal={handleInputResponseModal}
                    getRuleSets={getRuleSets}
                    surveyResponses={stats.total_responses}
                    member={member}
                    chartData={chartData}
                    chartQuestionIds={chartQuestionIds}
                    chartToggleHandler={chartToggleHandler}
                    surveyId={survey_id}
                    startDate={formattedStartDate}
                    endDate={formattedEndDate}
                    dateRange={dateRange}
                    research_type={surveyInfo ? surveyInfo.research_type : 'survey'}
                    handleUserDrawer={handleUserDrawer}
                    takeaways={surveyInfo && surveyInfo.takeaways ? surveyInfo.takeaways : []}
                    handleOthersModal={handleOthersModal}
                  />
                )}
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {isTabLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
                  <CircularProgress className={classes.loading} />
                </Box>
              ) : (
                <>
                  {surveyResponses && surveyResponses.length === 0 ? (
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Typography variant={'body2'} color={'text.secondary'}>
                        No responses to display
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {surveyResponses &&
                        surveyResponses.map((questions, idx) => (
                          <Box className={classes.questionBox} mb={2.5} key={idx}>
                            <Box className={classes.responseHeading} p={2}>
                              <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  className={!questions[0].user_id.startsWith('anonymous') ? classes.userBox : ''}
                                  onClick={e =>
                                    !questions[0].user_id.startsWith('anonymous') &&
                                    handleUserDrawer(e, questions[0].user_id, true)
                                  }
                                >
                                  <Box className={classes.avatar}>
                                    <img
                                      src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(
                                        questions[0].user_id,
                                        6,
                                      )}.png`}
                                      alt=""
                                    />
                                  </Box>
                                  <Typography variant={'subtitle1'} pl={1}>
                                    {questions[0].user_id}
                                  </Typography>
                                </Box>
                                <Typography variant={'subtitle1'} color={'#5E5E5E'}>
                                  {modifiedDate(questions[0].created_at, 'DD MMM `YY')}
                                </Typography>
                              </Box>
                              {questions[0] &&
                                questions[0].survey_properties &&
                                Object.keys(questions[0].survey_properties).length > 0 && (
                                  <Box className={classes.responseEventBox}>
                                    {Object.keys(questions[0].survey_properties).map((item, idx) => (
                                      <Typography variant={'subtitle1'} className={classes.responseEventItem} key={idx}>
                                        {item} :{' '}
                                        {item &&
                                        questions[0].survey_properties[item] !== null &&
                                        questions[0].survey_properties[item] !== undefined
                                          ? questions[0].survey_properties[item].toString()
                                          : ''}
                                      </Typography>
                                    ))}
                                  </Box>
                                )}
                            </Box>

                            <Box className={classes.responseBox} p={4} pt={0} alignItems="center">
                              {questions &&
                                questions.length > 0 &&
                                questions.map(
                                  (
                                    {
                                      question_order,
                                      question_text,
                                      response,
                                      raw_input,
                                      question_type,
                                      response_duration,
                                    },
                                    i,
                                  ) => (
                                    <div>
                                      <Box key={i}>
                                        <Box display="flex" alignItems="center" mt={2.5}>
                                          <div className={classes.conditionOrder}>
                                            <Typography variant={'subtitle2'}>Q{question_order}</Typography>
                                          </div>
                                          <Typography variant={'subtitle1'} color="#5E5E5E">
                                            {question_text}
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'} mt={0.5}>
                                          {+response_duration !== 0 ? (
                                            <>
                                              <AccessTimeIcon
                                                style={{
                                                  fontSize: 13,
                                                  color: 'grey',
                                                  marginTop: '2px',
                                                }}
                                              />
                                              <Typography
                                                variant={'body2'}
                                                pl={0.25}
                                                pr={0.5}
                                                style={{
                                                  color: 'grey',
                                                }}
                                              >
                                                {responseDurationTxt(response_duration)}
                                              </Typography>
                                            </>
                                          ) : (
                                            <Box ml={1.5}></Box>
                                          )}
                                          <img
                                            src={responseArrowImg}
                                            alt=""
                                            style={{
                                              marginTop: '2px',
                                            }}
                                          />
                                          <Typography pl={1}>
                                            {responseText(response, raw_input, question_type, showOriginal)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </div>
                                  ),
                                )}
                            </Box>
                          </Box>
                        ))}
                    </>
                  )}
                  {activeShowMore && (
                    <Typography
                      variant={'subtitle2'}
                      fontWeight={700}
                      textAlign={'right'}
                      onClick={() => fetchSurveyResponse(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      Show more &#8594;
                    </Typography>
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              {isTabLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
                  <CircularProgress className={classes.loading} />
                </Box>
              ) : (
                <>
                  {surveyViews.length === 0 ? (
                    <Box display="flex" justifyContent="center" mt={2}>
                      <Typography variant={'body2'} color={'text.secondary'}>
                        No data to display
                      </Typography>
                    </Box>
                  ) : (
                    <Grid container pt={1}>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableHeading}>User ID</TableCell>
                              <TableCell className={classes.tableHeading}>Last viewed on</TableCell>
                              <TableCell className={classes.tableHeading}>Survey status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {surveyViews.map(({ user_id, created_at, status }, index) => {
                              return (
                                <StyledTableRow key={index}>
                                  <TableCell component="th" scope="row" width={'50%'}>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      className={!user_id.startsWith('anonymous') ? classes.userBox : ''}
                                      onClick={e =>
                                        !user_id.startsWith('anonymous') && handleUserDrawer(e, user_id, true)
                                      }
                                    >
                                      <Box className={classes.avatar}>
                                        <img
                                          src={`https://cdn.blitzllama.com/default/avatars/avatar-${getImageId(
                                            user_id,
                                            6,
                                          )}.png`}
                                          alt=""
                                        />
                                      </Box>
                                      <Typography variant={'subtitle1'} pl={1}>
                                        {user_id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell width={'30%'}>
                                    <Typography variant={'subtitle1'} color={'#5E5E5E'}>
                                      {modifiedDate(created_at, 'hh:mm a, DD MMM `YY')}
                                    </Typography>
                                  </TableCell>

                                  <TableCell width={'20%'}>
                                    <Typography
                                      variant={'subtitle1'}
                                      className={status === 'Unanswered' ? classes.greyColor : ''}
                                    >
                                      {status}
                                    </Typography>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                  {viewShowMore && (
                    <Typography
                      variant={'subtitle2'}
                      fontWeight={700}
                      textAlign={'right'}
                      onClick={() => fetchSurveyView(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      Show more &#8594;
                    </Typography>
                  )}
                </>
              )}
            </TabPanel>
          </Grid>
          <Grid
            item
            lg={4}
            style={{
              textAlign: 'center',
            }}
          >
            {surveyInfo &&
              (surveyInfo.research_type === 'prototype' ? (
                <PrototypePreview surveyInfo={surveyInfo} />
              ) : (
                <SurveyDemo surveyInfo={surveyInfo} handleSurveySettings={handleSurveySettings} />
              ))}
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default SurveyPerformanceComponent;
