import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Paper } from '@mui/material';

import AlertUtil from '../../../utils/AlertUtil';
import { amplitudeEvent } from '../../../utils/amplitudeUtil';

import { RootState } from '../../../store';

import { useStyles } from './Report.style';
import { getAllReportList } from '../../../services/insightService';
import { IReportList } from '../../../types/insight';
import { LinearProgressWithLabel } from '../../../utils/ProgressUtil';
import moment from 'moment';
import { DoughnutComponent } from '../../spotlight/charts/DoughChartComponent';
import { DailyChartComponent } from '../../spotlight/charts/DailyChartComponent';
const frequencyReportTypes = ['Daily Report', 'Weekly Report', 'Monthly Report'];

const ReportHomeComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [reportList, setReportList] = useState<Array<IReportList>>([]);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'report home page viewed', {});
      if (tracking_info && !tracking_info.show_discovery) {
        window.location.href = '/';
      }
    }
  }, [tracking_info]);

  const getAllData = async () => {
    const reports = await getAllReportList();
    setReportList(reports);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div>
      <Box mb={1}>
        <Box display={'flex'} width={'75%'}>
          <Typography fontSize={'2.2rem'} fontWeight={700} mt={-0.5}>
            Reports
          </Typography>
        </Box>

        <Grid container spacing={3} alignItems="stretch" mt={2}>
          {reportList.map(
            ({ insight_report_id, report_name, themes, total_reports, updated_at, chart = {}, intent = [] }, idx) => (
              <Grid
                item
                lg={6}
                key={0}
                onClick={() => {
                  history.push(
                    frequencyReportTypes.includes(report_name)
                      ? `/reports/feq/${insight_report_id}`
                      : `/reports/${insight_report_id}`,
                  );
                }}
              >
                <Paper className={classes.dashboardPaper}>
                  <Box display="flex" style={{ height: '380px' }} flexDirection={'column'}>
                    <Box>
                      <Typography variant={'subtitle1'} fontWeight={'bold'} fontSize={14}>
                        {report_name}
                      </Typography>
                    </Box>
                    <Box>
                      {/* <Typography className={classes.reportTagText} mb={1}>
                      User experience
                    </Typography> */}
                    </Box>

                    {!frequencyReportTypes.includes(report_name) && (
                      <Box height={'300px'} style={{ overflowY: 'scroll' }}>
                        {themes.map(theme => (
                          <LinearProgressWithLabel
                            count={theme.reports}
                            value={+((theme.reports / total_reports) * 100).toFixed(0)}
                            label={theme.theme_name}
                            handleOthersModal={() => {}}
                            select_input_texts={[]}
                          />
                        ))}
                      </Box>
                    )}

                    {frequencyReportTypes.includes(report_name) &&
                      (report_name === 'Daily Report' ? (
                        <Box mt={2} height={'300px'}>
                          <DoughnutComponent data={intent} chartHeight={200} />
                        </Box>
                      ) : (
                        <Box mt={3} height={'300px'}>
                          <Box width={'100%'} mt={2} mr={1}>
                            <DailyChartComponent
                              x_axis={chart && chart.x_axis ? chart.x_axis : []}
                              data_groups={chart && chart.data_groups ? chart.data_groups : []}
                              question_type={'input_feedback'}
                              chartHeight={280}
                              is_dashboard={true}
                            />
                          </Box>
                        </Box>
                      ))}

                    <Typography variant={'subtitle2'} color={'gray'} mt={3}>
                      Data from {moment(updated_at).fromNow()}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ),
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default ReportHomeComponent;
