import { putCall, getCall, postCall } from '../apiCall';
import { IInsightUrl } from '../types/insight';
import {
  PIN_INSIGHT,
  UNPIN_INSIGHT,
  FETCH_INSIGHTS,
  EDIT_PIN_TITLE,
  FETCH_WORKSPACE_THEMES,
  FETCH_INSIGHTS_URL,
  UPDATE_INSIGHTS_URL,
  FETCH_ALL_THEME_INSIGHTS,
  FETCH_INSIGHTS_BY_URL,
  FETCH_WORKSPACE_THEMES_STATUS,
  DISCONNECT_INSIGHTS_BY_URL,
  UPLOAD_CSV_FOR_INSIGHTS,
  FETCH_INSIGHTS_IN_REPO,
  FETCH_EMOTIONS_FROM_TEXTS,
  FETCH_WORKSPACE_DESC,
  UPDATE_WORKSPACE_DESC,
  UPLOAD_CSV_FOR_STATUS,
  FETCH_ALL_REPORTS_LIST,
  UPDATE_REPORT_BY_ID,
  FETCH_REPORT_BY_ID,
  UPLOAD_CSV_FILES_FOR_INSIGHTS,
} from './apiEndPoints';

export const pinInsight = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(PIN_INSIGHT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const unpinInsight = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(UNPIN_INSIGHT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllInsights = async () => {
  return new Promise<Array<any>>((resolve, reject) => {
    try {
      getCall(FETCH_INSIGHTS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editPinTitle = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_PIN_TITLE(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchWorkspaceThemes = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_WORKSPACE_THEMES(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchInsightsUrl = async () => {
  return new Promise<Array<IInsightUrl>>((resolve, reject) => {
    try {
      getCall(FETCH_INSIGHTS_URL())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateInsightsUrl = async payload => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(UPDATE_INSIGHTS_URL(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const disconnectInsightsUrl = async (source: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(DISCONNECT_INSIGHTS_BY_URL(source), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getInsightsByThemeId = async (theme_id: number, payload) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_ALL_THEME_INSIGHTS(theme_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getInsightsByUrlInit = async (source: string, payload) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_INSIGHTS_BY_URL(source), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getInsightsStatus = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_THEMES_STATUS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const uploadCSVDataForInsights = async payload => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(UPLOAD_CSV_FOR_INSIGHTS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getInsightsInRespository = async payload => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_INSIGHTS_IN_REPO(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getEmotionsFromTexts = async payload => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_EMOTIONS_FROM_TEXTS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getWorkspaceCSVList = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(UPLOAD_CSV_FILES_FOR_INSIGHTS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getWorkspaceDesc = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(FETCH_WORKSPACE_DESC())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateWorkspaceDesc = async payload => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(UPDATE_WORKSPACE_DESC(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getCSVUploadStatus = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(UPLOAD_CSV_FOR_STATUS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getAllReportList = async () => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_REPORTS_LIST())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getReportById = async (id: string, should_refresh: boolean) => {
  return new Promise<any>((resolve, reject) => {
    try {
      getCall(FETCH_REPORT_BY_ID(id, should_refresh))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateReportById = async (id: string, payload) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(UPDATE_REPORT_BY_ID(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
