/* eslint-disable @typescript-eslint/no-inferrable-types */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Drawer, Button, Theme, TextField, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ReplayIcon from '@mui/icons-material/Replay';
import StarIcon from '@mui/icons-material/Star';

import deleteImg from '../../images/delete-icon.svg';
import emptyStateImg from '../../images/emptyState/search.svg';
import {
  fetchInsightsUrl,
  getInsightsByUrlInit,
  updateInsightsUrl,
  disconnectInsightsUrl,
} from '../../services/insightService';
import { capitalizeFirstLetter, userImgFormat } from '../../utils/stringUtil';
import { connectionData } from '../../static/connectionData';
import moment from 'moment';
import AlertUtil from '../../utils/AlertUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    deleteImg: {
      paddingLeft: theme.spacing(4),
      height: '36px',
      cursor: 'pointer',
    },
    border: {
      paddingTop: theme.spacing(5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    greenBox: {
      background: '#3ABD8E1A',
      display: 'flex',
      alignItems: 'center',
      color: '#3ABD8E',
      padding: theme.spacing(2),
      paddingBlock: theme.spacing(1.2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    textFieldBox: {
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(2.5),
      paddingBlock: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      overflowY: 'scroll',
      whiteSpace: 'nowrap',
    },
    copyBox: {
      cursor: 'pointer',
      background: 'rgba(34, 238, 226, 0.05)',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      borderRadius: 5,
      padding: theme.spacing(1.5),
      paddingBlock: theme.spacing(0.6),
      textTransform: 'none',
    },

    copiedIcon: {
      color: theme.palette.primary.contrastText,
    },
    connectionTextField: {
      '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
      },
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '60%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem' },
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    userImgBox: {
      borderRadius: '50%',
      width: '30px',
      height: '30px',
    },
  }),
);

export default function InsightConnectionDrawer(props: {
  toggleDrawer: (Boolean) => void;
  rightState: boolean;
  source: string;
}) {
  const { toggleDrawer, rightState, source = 'playstore' } = props;
  const classes = useStyles();
  const [insightsData, setInsightsData] = useState<any>([]);
  const [sourceUrl, setSourceUrl] = useState<string>('');
  const [sourceInfo, setSourceInfo] = useState<{
    source: string;
    source_url: string;
    insight_store_id: string;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getInsights = async (source_url: string, insight_store_id: string) => {
    setIsLoading(true);
    const results = await getInsightsByUrlInit(source, { source_url, insight_store_id });
    if (results && results.data) {
      setInsightsData(results.data);
    }
    setIsLoading(false);
  };

  const fetchInsightsData = async () => {
    const results = await fetchInsightsUrl();
    if (results && results.length > 0) {
      const item = results.find(r => r.source === source);
      if (item && item.source_url) {
        setSourceInfo(item);
        setSourceUrl(item.source_url);
        await getInsights(item.source_url, item.insight_store_id);
      }
    } else {
      setSourceInfo(undefined);
      setSourceUrl('');
    }
    setIsLoading(false);
  };

  const updateInsightsData = async () => {
    const results = await updateInsightsUrl({ source, source_url: sourceUrl });
    if (results) {
      fetchInsightsData();
      if (sourceInfo) {
        getInsights(sourceUrl, sourceInfo.insight_store_id);
      }
    }
  };

  const removeInsightsUrl = async () => {
    const results = await disconnectInsightsUrl(source);
    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'removed successfully',
      });
    }
    fetchInsightsData();
  };

  useEffect(() => {
    fetchInsightsData();
  }, []);

  return (
    <Drawer
      anchor={'right'}
      open={rightState}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box pt={5} sx={{ width: 800 }}>
        <img src={deleteImg} alt="" onClick={toggleDrawer} className={classes.deleteImg} />
        {/* <Box mt={2} ml={6} mr={6} display={'flex'} justifyContent={'space-between'}>
          <Typography fontWeight={'bold'}>Add new source</Typography>
          <Button
            variant="contained"
            onClick={() => {
              getInsights();
            }}
          >
            + Generate Insights
          </Button>
        </Box> */}
        <Box mt={1} ml={7} mr={9}>
          <Box display="flex">
            <img
              src={
                connectionData.feedback_sources &&
                connectionData.feedback_sources.find(f => f.connection_value === source)
                  ? connectionData.feedback_sources.find(f => f.connection_value === source)?.connection_img
                  : ''
              }
              alt=""
              style={{ width: '81px', height: '81px' }}
            />

            <Box width="100%" ml={3} mr={1}>
              <Typography variant={'h4'}>{capitalizeFirstLetter(source)}</Typography>
              <Typography lineHeight={1.5} mt={0.2}>
                Sync {source} reviews and generate insights from what your users are saying.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box mt={6} ml={6} mr={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Add {source} app link
          </Typography>
          <TextField
            fullWidth
            className={classes.connectionTextField}
            value={sourceUrl}
            placeholder={`Paste your app's ${source} URL here...`}
            onChange={e => setSourceUrl(e.target.value)}
          />
          <Button variant="contained" style={{ marginTop: 20 }} onClick={() => updateInsightsData()}>
            Save Changes
          </Button>
          <Button variant="outlined" style={{ marginTop: 20, marginLeft: 10 }} onClick={() => removeInsightsUrl()}>
            Disconnect
          </Button>
        </Box>

        <Box mt={5} ml={6} mr={6} display={'flex'} justifyContent={'center'}>
          {isLoading && (
            <>
              <Box>
                <CircularProgress size={18} thickness={7} sx={{ color: 'black' }} />
              </Box>
              <Typography variant="subtitle1" ml={1.5} mt={-0.5}>
                Loading data
              </Typography>
            </>
          )}
        </Box>

        {insightsData.length === 0 && !isLoading && (
          <Box minHeight={'75vh'} px={6} my={2} py={2} style={{ backgroundColor: '#F2F2F6' }}>
            <Box pt={8} style={{ textAlign: 'center' }}>
              <img src={emptyStateImg} alt="" />
              <Typography
                variant={'subtitle1'}
                maxWidth={250}
                textAlign={'center'}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                Add your app’s link and hold on while we gather insights for you...
              </Typography>
            </Box>
          </Box>
        )}

        {insightsData.length > 0 && (
          <Box ml={7} mr={9}>
            {insightsData.map(item => (
              <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box display="flex" alignItems="center">
                    {item.userImage ? (
                      <img src={item.userImage} alt="" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.userImgBox}
                        style={{
                          backgroundColor: `${userImgFormat(item.userName, 'member_img').bgColor}`,
                        }}
                      >
                        <Typography style={{ fontSize: '0.95rem', fontWeight: 700 }}>
                          {userImgFormat(item.userName, 'member_img').name}
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      variant={'subtitle1'}
                      fontWeight={'bold'}
                      style={{ paddingLeft: 10, maxWidth: '25ch', paddingRight: 5 }}
                      noWrap
                    >
                      {item.userName}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <div style={{ marginTop: '4px', width: '120px' }}>
                      {Array(+item.score)
                        .fill(0)
                        .map(() => (
                          <StarIcon />
                        ))}
                    </div>
                    <Typography ml={1} variant="subtitle1" color="text.secondary">
                      {moment(item.date).fromNow()}
                    </Typography>
                  </Box>
                </Box>
                <Typography my={1.5} lineHeight={1.3} mb={4}>
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
