import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import validator from 'validator';
import { Container, Grid, Typography, Box, TextField, Button } from '@mui/material';

import defaultStyles from './loginStyles';

import LoginTestimonial from './LoginTestimonial';
import { checkWorkEmail } from '../../utils/LoginUtil';
import { ErrorUtil } from '../../utils/MessageUtil';
import { signupMember } from '../../services/loginService';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import ENV from '../../config';

import googleImg from '../../images/login/google-login.svg';

const VerifyComponent = (props: { setShowVerify: (value: boolean) => void; email: string; isAutoVerfify: boolean }) => {
  const common = defaultStyles();
  const { setShowVerify, email, isAutoVerfify } = props;
  return (
    <div>
      <Typography className={common.loginTitle} textAlign={'center'}>
        Signup
      </Typography>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems={'center'}
        justifyContent={'center'}
        className={common.borderBox}
      >
        {isAutoVerfify ? (
          <Typography>🎉 Please confirm your email address by clicking the link we just sent to your inbox.</Typography>
        ) : (
          <Typography textAlign={'center'}>
            🎉 Thank you for your signing up with Blitzllama. A member of our team will contact you shortly.
          </Typography>
        )}
        <Typography fontWeight={700} mt={4.5}>
          {email}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color="primary.contrastText"
          mt={4.5}
          style={{ cursor: 'pointer' }}
          onClick={() => setShowVerify(false)}
        >
          Change Email ID
        </Typography>
      </Box>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SignUpComponent() {
  const common = defaultStyles();
  const history = useHistory();

  //login check
  const auth_token = localStorage.getItem('auth_token');
  if (auth_token) {
    window.location.href = '/';
  }

  const query = useQuery();
  const referrer = query.get('utm_source') || '';
  const q_email = query.get('email') || '';

  const [email, setEmail] = useState(q_email);

  const [message, setMessage] = useState('' as string);
  const [showVerify, setShowVerify] = useState(false);
  const [isAutoVerfify, setAutoVerfify] = useState(false);

  useEffect(() => {
    amplitudeEvent(
      {
        member_id: '',
        member_email: '',
        org_id: '',
        org_name: '',
        workspace_id: '',
        workspace_name: '',
        show_discovery: false,
      },
      'signup page viewed',
      {},
    );
  }, []);

  const handleSubmit = async (evt: { preventDefault: () => void } | null) => {
    const formatted_email = email.toLowerCase().trim();
    if (evt) {
      evt.preventDefault();
    }

    if (!formatted_email) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (!validator.isEmail(formatted_email)) {
      setMessage('Email is invalid');
      setTimeout(() => setMessage(''), 3000);
      return;
    }

    const isValidWorkEmail = checkWorkEmail(formatted_email);

    if (isValidWorkEmail) {
      const signupResult = await signupMember({ email: formatted_email, referrer });

      if (signupResult && signupResult.data) {
        if (signupResult.data.success) {
          if (window['gtag']) {
            window['gtag']('event', 'conversion', { send_to: 'AW-379793372/iY8QCI_c96EYENzfjLUB' });
          }
          setShowVerify(true);
          amplitudeEvent(
            {
              member_id: '',
              member_email: '',
              org_id: '',
              org_name: '',
              workspace_id: '',
              workspace_name: '',
              show_discovery: false,
            },
            'signup details',
            { email: formatted_email },
          );

          if (signupResult.data.message === 'approved') {
            setAutoVerfify(true);
          }
        } else {
          if (signupResult.data.message === 'duplicate org') {
            setMessage(
              '*This organization already exists. Please contact your workspace admin or chat with us for access.',
            );
            setTimeout(() => setMessage(''), 3000);
          } else if (signupResult.data.message === 'already-signup') {
            setShowVerify(true);
          } else {
            setMessage('*This email ID already exists. Please login instead.');
            setTimeout(() => setMessage(''), 3000);
          }
        }
      }
    } else {
      setMessage('*Please enter a valid work email.');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  useEffect(() => {
    if (q_email) {
      setTimeout(() => {
        handleSubmit(null);
      }, 500);
    }
  }, []);

  return (
    <Grid container>
      <Grid item lg={7}>
        <LoginTestimonial />
      </Grid>
      <Grid item lg={5} direction="column" justifyContent="center" className={common.root}>
        <Container maxWidth="lg" className={common.formContainer}>
          <Grid lg={9} container direction="column" justifyContent="center">
            {showVerify ? (
              <VerifyComponent email={email} setShowVerify={setShowVerify} isAutoVerfify={isAutoVerfify} />
            ) : (
              <>
                <Typography className={common.loginTitle} textAlign={'center'}>
                  Get started with Blitzllama
                </Typography>
                <Box className={common.borderBox}>
                  <Typography variant="subtitle1">
                    Work email ID <span style={{ color: '#F42B4F' }}>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter email id"
                    name="email"
                    value={email}
                    autoComplete="off"
                    onChange={e => setEmail(e.target.value)}
                  />

                  <Button variant="contained" className={common.button} type="submit" onClick={e => handleSubmit(e)}>
                    Verify & sign up
                  </Button>
                  <Box display="flex" alignItems={'center'}>
                    <div className={common.loginSeparator}></div>
                    <Typography variant="subtitle1" color="text.secondary" fontWeight={700} mx={1}>
                      OR
                    </Typography>
                    <div className={common.loginSeparator}></div>
                  </Box>
                  <Button
                    className={common.googleLoginButton}
                    onClick={() =>
                      (window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${ENV.google_auth_url}&client_id=${ENV.google_client_id}&access_type=offline&response_type=code&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&state=${window.location.origin}`)
                    }
                  >
                    <Box display="flex" alignItems="center">
                      <Box className={common.googleLoginBox}>
                        <img src={googleImg} />
                      </Box>
                      <Typography variant="subtitle1" fontWeight={700} color={'black'} pl={1}>
                        Sign up with Google
                      </Typography>
                    </Box>
                  </Button>
                  {message !== '' && <ErrorUtil message={message} />}
                  <Box textAlign={'center'} mt={3.2}>
                    <Typography display={'inline'} variant="subtitle1">
                      Already on Blitzllama?{' '}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      display={'inline'}
                      color="primary.contrastText"
                      onClick={() => history.push('/login')}
                      style={{ cursor: 'pointer' }}
                    >
                      Login
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            <Box textAlign={'center'} mt={3.2}>
              <Typography display={'inline'} variant="subtitle1">
                By signing up, you agree to the{' '}
              </Typography>
              <a href={'https://blitzllama.com/terms'} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="subtitle1"
                  display={'inline'}
                  color="primary.contrastText"
                  style={{ cursor: 'pointer' }}
                >
                  Terms of Use{' '}
                </Typography>
              </a>
              <Typography display={'inline'} variant="subtitle1">
                and{' '}
              </Typography>
              <a href={'https://blitzllama.com/privacy'} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="subtitle1"
                  display={'inline'}
                  color="primary.contrastText"
                  style={{ cursor: 'pointer' }}
                >
                  Privacy Policy
                </Typography>
              </a>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default SignUpComponent;
