import React, { FunctionComponent } from 'react';
import { Box, Popover, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DateRange, LocalizationProvider, StaticDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useStyles } from './Spotlight.style';

import { calenderData } from './constants';
import { modifiedDate, modifiedDateNoOffset } from '../../utils/DateUtil';
import { IDateRange } from './spotlight';

import expandImg from '../../images/spotlight/expand-icon.svg';

export const DateFilter: FunctionComponent<{
  date_range: IDateRange;
  handleDateChanges: (range_type: string, field_name: string, value: string | number) => void;
  hideMonths?: boolean;
  hide1D?: boolean;
}> = ({ date_range, handleDateChanges, hideMonths = false, hide1D = false }) => {
  const classes = useStyles();

  const [DateAnchor, setDateAnchor] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  const { days_count, type, time_from, time_to } = date_range;

  const open = Boolean(DateAnchor);
  const id = open ? 'simple-popover' : undefined;
  const handleDateClose = () => {
    setDateAnchor(null);
  };

  const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setDateAnchor(event.currentTarget);
    }
  };

  //handle date formatting
  let formatted_time_from = modifiedDateNoOffset(new Date().toJSON(), 'DD MMM YYYY');
  let formatted_time_to = modifiedDateNoOffset(new Date().toJSON(), 'DD MMM YYYY');

  if (date_range.type === 'custom') {
    if (time_from) {
      formatted_time_from = modifiedDateNoOffset(time_from.toString(), 'DD MMM YYYY');
    }

    if (time_to) {
      formatted_time_to = modifiedDateNoOffset(time_to.toString(), 'DD MMM YYYY');
    }
  }

  //alter values to show less months in survey details page
  let reqCalendarData = calenderData;
  if (hideMonths) {
    reqCalendarData = calenderData.filter(c => c.value !== '60' && c.value !== '90');
  }

  if (hide1D) {
    reqCalendarData = reqCalendarData.filter(c => c.value !== '1');
  }
  return (
    <ToggleButtonGroup exclusive>
      {reqCalendarData.map(({ title, value }, idx) => (
        <ToggleButton
          value={value}
          className={
            type === 'days' && days_count.toString() === value ? classes.activeToggleButton : classes.toggleButton
          }
          key={idx}
          onClick={e => handleDateChanges('days', 'days_count', value)}
        >
          <Typography variant="subtitle1">{title}</Typography>
        </ToggleButton>
      ))}

      <ToggleButton value="custom" className={type === 'custom' ? classes.activeToggleButton : classes.toggleButton}>
        <Popover
          id={id}
          open={open}
          anchorEl={DateAnchor}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              className={classes.dateRangePopup}
              disableFuture
              value={value}
              onChange={newValue => {
                setValue(newValue);
                handleDateChanges(
                  'custom',
                  'time_from',
                  newValue[0] ? modifiedDateNoOffset(newValue[0].toString(), 'yyyy-MM-DD') : '',
                );
                handleDateChanges(
                  'custom',
                  'time_to',
                  newValue[1] ? modifiedDateNoOffset(newValue[1].toString(), 'yyyy-MM-DD') : '',
                );
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box
                    sx={{
                      mx: 2,
                    }}
                  >
                    {' '}
                    to{' '}
                  </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Popover>
        <Box className={classes.dateRangeBox} onClick={e => handleDateClick(e)}>
          <Box display="flex" alignItems="center">
            {/* <img src={calenderImg} alt="" /> */}
            {type === 'days' ? (
              <Typography ml={1} variant="subtitle1">
                Custom
              </Typography>
            ) : (
              <Box display={'flex'} alignItems={'center'}>
                <Typography
                  variant="subtitle1"
                  width={'10ch'}
                  ml={0.5}
                  mr={0.5}
                  color={type === 'custom' ? 'primary.contrastText' : ''}
                >
                  {formatted_time_from}
                </Typography>
                <Typography variant="subtitle1">-</Typography>
                <Typography
                  variant="subtitle1"
                  width={'10ch'}
                  ml={0.5}
                  mr={0.5}
                  color={type === 'custom' ? 'primary.contrastText' : ''}
                >
                  {formatted_time_to}
                </Typography>
                <img
                  src={expandImg}
                  alt=""
                  style={{
                    marginTop: 5,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export const getDateRangeValue = (date_range: IDateRange) => {
  if (!date_range) {
    return '';
  }
  if (date_range && date_range.type && date_range.type === 'days') {
    return `Last ${date_range.days_count} days`;
  }

  if (date_range && date_range.type && date_range.type === 'custom') {
    return `${modifiedDate(date_range.time_from, 'll')} to ${modifiedDate(date_range.time_to, 'll')} `;
  }
};
