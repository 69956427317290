import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Container,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ErrorUtil, SuccessUtil } from '../../utils/MessageUtil';
import defaultStyles from './loginStyles';
import { loginMember, resetPassword } from '../../services/loginService';
import { memberJobRole } from '../../static/staticData';
import { editMemberDetails } from '../../services/organisationMemberService';
import { tokenEmailInfo } from '../../services/loginService';
import LoginTestimonial from './LoginTestimonial';
import { fetchOrganisation } from '../../services/organisationService';

const useStyles = makeStyles(theme => ({
  inputColor: {
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem', fontSize: '14px' },
  },

  tokenExpire: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(18),
  },
  loading: {
    color: theme.palette.primary.contrastText,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function WelcomeComponent() {
  const classes = useStyles();
  const common = defaultStyles();
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [workRole, setWorkRole] = useState('developer');
  const [isLoading, setLoading] = useState(true);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorToken, setErrorToken] = useState(true);

  const query = useQuery();

  const token = query.get('token');

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [blockSubmit, setBlockSubmit] = useState(false);

  const fetchEmailByToken = async (token: string | null) => {
    if (token === null || token === '') {
      setErrorToken(true);
      return;
    }

    const tokenEmailResult = await tokenEmailInfo(token);
    if (tokenEmailResult && tokenEmailResult.email) {
      setEmail(tokenEmailResult.email);
      setErrorToken(false);
    } else {
      setErrorToken(true);
    }
  };

  useEffect(() => {
    fetchEmailByToken(token);
    setLoading(false);
  }, []);

  const handleResetClick = async () => {
    if (!password) {
      setError('Password cannot be empty');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (password !== confirmPassword) {
      setError('passwords are not same');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (!token) {
      setError('Token is not there. Contact chat for support');
      setTimeout(() => setError(''), 3000);
      return;
    } else if (password.length < 6) {
      setError('Password must be atleast 6 characters');
      setTimeout(() => setError(''), 3000);
      return;
    }

    const resetResults = await resetPassword({ token, new_password: password });

    if (resetResults && resetResults.data) {
      setBlockSubmit(true);
      const loginResults = await loginMember({ email, password });

      if (loginResults && loginResults.auth_token) {
        await localStorage.setItem('auth_token', loginResults.auth_token);
        const results = await editMemberDetails({
          member_job_type: workRole,
          member_name: name,
        });

        if (results && results.data) {
          // handle skip_org
          const org_result = await fetchOrganisation();

          if (org_result) {
            const { skip_org } = org_result;
            if (skip_org) {
              window.location.href = '/';
            } else {
              history.push('/org-details');
            }
          }
        } else {
          setMessage('Error. Please try again');
          setTimeout(() => setMessage(''), 3000);
        }
      } else {
        setMessage(loginResults?.error);
        setTimeout(() => setMessage(''), 3000);
      }
    } else {
      setError(resetResults.error);
      setTimeout(() => setError(''), 3000);
    }

    setBlockSubmit(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        {errorToken ? (
          <Grid container lg={12} className={classes.tokenExpire}>
            <Box width="350px" p={5} textAlign="center" border={1} borderColor={'divider'} borderRadius={2.5}>
              <Typography variant={'h4'}>
                <span role="img" aria-label="emoji">
                  ✨
                </span>
                Invite Link has expired.
              </Typography>

              <Typography variant={'subtitle1'} mt={2}>
                Please get in touch with your admin to resolve issues.
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid container>
            <Grid item lg={7}>
              <LoginTestimonial />
            </Grid>
            <Grid item lg={5} direction="column" justifyContent="center" className={common.root}>
              <Container maxWidth="lg" className={common.formContainer}>
                <Grid lg={9} container direction="column" justifyContent="center">
                  <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={blockSubmit}>
                    <Box display={'flex'} flexDirection="column" textAlign={'center'}>
                      <div>
                        <CircularProgress color="inherit" />
                      </div>
                      <Typography mt={1}>Setting up your account</Typography>
                    </Box>
                  </Backdrop>
                  <Typography className={common.loginTitle} textAlign={'center'}>
                    Complete setting up your account
                  </Typography>
                  <Box className={common.borderBox}>
                    <Typography variant="subtitle1">
                      Your full name <span style={{ color: '#F42B4F' }}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className={common.textField}
                      placeholder="Enter name"
                      autoComplete="off"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />

                    <Typography variant="subtitle1" mt={2}>
                      Your role
                    </Typography>
                    <TextField
                      variant="outlined"
                      select
                      fullWidth
                      className={common.textField}
                      value={workRole}
                      onChange={e => setWorkRole(e.target.value)}
                    >
                      {memberJobRole.map(({ value, label }, idx) => {
                        return (
                          <MenuItem key={idx} value={value}>
                            <Typography variant="subtitle1">{label}</Typography>
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <Typography variant="subtitle1" mt={2}>
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      name="password"
                      placeholder="Enter password"
                      autoComplete="off"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      className={classes.inputColor}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              size="large"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Typography variant="subtitle1" mt={2}>
                      Confirm password
                    </Typography>
                    <TextField
                      fullWidth
                      name="confirm password"
                      placeholder="Enter password again"
                      autoComplete="off"
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      className={classes.inputColor}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              size="large"
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {error !== '' && <ErrorUtil message={error} />}
                    {message !== '' && <SuccessUtil message={message} />}

                    <Button
                      variant="contained"
                      className={common.button}
                      type="submit"
                      onClick={() => handleResetClick()}
                    >
                      Create my account
                    </Button>
                  </Box>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default WelcomeComponent;
