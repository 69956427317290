import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Typography, Box, LinearProgress, Theme } from '@mui/material';
import { ProgressColorUtil } from './ProgressColorUtil';
import { numberWithCommas } from './stringUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      height: '4px',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    themeText: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
);

interface ProgressItem {
  value: number;
  count?: number;
  label?: string;
  change_perc?: number;
  onClickHandler: () => void;
}

export const LinearProgressForReport: FunctionComponent<ProgressItem> = ({
  value,
  count = 0,
  label,
  change_perc = 0,
  onClickHandler,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        {label && (
          <Typography
            variant={'subtitle1'}
            display="inline"
            onClick={() => {
              onClickHandler();
            }}
            className={classes.themeText}
          >
            {label}
          </Typography>
        )}
        <Box display={'flex'}>
          <Typography variant={'subtitle1'} style={{ overflow: 'unset' }} noWrap>
            {count ? `${numberWithCommas(count)}` : `-`}
          </Typography>
          <Typography variant={'subtitle1'} display="inline" ml={2}>
            {change_perc ? `${Math.round(change_perc)}%` : '-'}
          </Typography>
        </Box>
      </Box>
      <Box width="100%" mt={0.5} pb={2.5}>
        <LinearProgress
          variant="determinate"
          value={value}
          className={classes.progress}
          sx={{
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: ProgressColorUtil(value),
            },
          }}
        />
      </Box>
    </>
  );
};
